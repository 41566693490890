<template>
  <v-row dense>
    <v-col
      class="pl-10"
      cols="12"
      md="4"
    >
      <v-card
        max-height="200"
        height="200"
        class="overflow-auto"
      >
        <v-card-title
          primary-title
          class="secondary--text subtitle-2"
          v-text="'Cores'"
        />
        <v-card-text>
          <v-item-group
            v-model="selectedColor"
            mandatory
          >
            <v-container fluid>
              <v-row dense>
                <v-col
                  v-for="color in colors"
                  :key="color.id"
                  cols="12"
                  md="3"
                >
                  <v-item
                    v-slot="{ active, toggle }"
                    :value="color.id"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          class="elevation-0"
                          height="50"
                          width="50"
                          @click="toggle"
                        >
                          <v-avatar
                            class="elevation-3"
                            v-bind="attrs"
                            rounded
                            v-on="on"
                          >
                            <v-img
                              :src="$store.state.imagePath + color.image_color"
                              :alt="color.name"
                            >
                              <v-overlay
                                absolute
                                :value="active"
                              >
                                <v-scroll-y-transition>
                                  <v-icon
                                    v-if="active"
                                    small
                                    dark
                                  >
                                    fas fa-check
                                  </v-icon>
                                </v-scroll-y-transition>
                              </v-overlay>
                            </v-img>
                          </v-avatar>
                        </v-card>
                      </template>
                      <span>{{ color.name }}</span>
                    </v-tooltip>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      class="pr-10"
      cols="12"
      md="8"
    >
      <v-slide-group
        class="px-4"
        show-arrows="always"
      >
        <v-slide-item
          v-for="(image, idx) in images"
          :key="idx"
          class="ma-1"
        >
          <v-img
            :src="getSrc(image.src)"
            class="elevation-3"
            max-width="200"
            max-height="200"
            width="200"
            height="200"
            :gradient="
              showBtn === idx
                ? 'to top right, rgba(0,0,0,0), rgba(0,0,0,.1)'
                : ''
            "
            @mouseover="showBtn = idx"
            @mouseleave="showBtn = null"
          >
            <transition name="slideIn">
              <v-btn
                v-if="showBtn === idx"
                class="error"
                dark
                absolute
                right
                top
                icon
                @click="removeImage(image)"
              >
                <v-icon small> fas fa-trash </v-icon>
              </v-btn>
            </transition>
          </v-img>
        </v-slide-item>
        <v-slide-item>
          <v-card
            class="ma-1 elevation-0 d-flex align-center justify-center u-border-dashed"
            height="200"
            width="200"
            @click="addImage"
          >
            <v-icon color="primary"> fas fa-plus </v-icon>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <input
        id="product_image"
        ref="uploader"
        class="d-none"
        type="file"
        multiple
        accept="image/*"
        @change="onFileChanged"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
    },
  },
  data: () => ({
    selectedColor: null,
    showBtn: null,
    colors: [],
    imgs: [],
  }),
  mounted() {
    this.getColors()
    console.log(this.images)
  },
  methods: {
    getColors() {
      this.$api
        .get('product_colors')
        .then((res) => {
          this.colors = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.colors.isLoading = false
        })
    },
    addImage() {
      this.$refs.uploader.click()
    },
    removeImage(img) {
      let idx = this.images.indexOf(img)
      this.images.splice(idx, 1)
    },
    onFileChanged(e) {
      this.images.push({
        src: e.target.files[0],
        color_id: this.selectedColor,
      })
    },
    getSrc(src) {
      if (typeof src == 'object') {
        return URL.createObjectURL(src)
      } else {
        return this.$store.state.imagePath + src
      }
    },
  },
}
</script>

<style lang="scss">
.u-border-dashed {
  border: 2px dashed $primary !important;
}

.slideIn-enter,
.slideIn-leave-to {
  transform: translate(40px, -40px);
}

.slideIn-enter-active,
.slideIn-leave-active {
  transition: all 0.2s;
}

.slideIn-enter-to,
.slideIn-leave {
  transform: translate(0, 0);
}
</style>
